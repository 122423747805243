var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"content-fix org"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('a-button',{staticClass:"back-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'activity'
        })}}},[_c('i',{staticClass:"t-transition icons icon-chevron-left"}),_vm._v(" Назад ")]),_c('a-row',{staticClass:"post-search",attrs:{"type":"flex"}},[_c('a-select',{staticClass:"table__parpage",attrs:{"option-filter-prop":"children","placeholder":"Все"},on:{"change":_vm.tableperPage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.LimitList),function(limit,index){return _c('a-select-option',{key:index,attrs:{"value":limit.value}},[_vm._v(" "+_vm._s(limit.title)+" ")])}),1),_c('a-button',{staticClass:"all-news-btn",staticStyle:{"margin":"0 15px"},attrs:{"type":"primary"},on:{"click":_vm.Allnews}},[_vm._v(" Все ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createFunc()}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("Add"))+" ")],1)],1),_c('a-row',{staticStyle:{"width":"100%","justify-content":"space-between"},attrs:{"type":"flex"}},[_c('h1',[_vm._v(_vm._s(_vm.headTitle[_vm.$route.params.type].title[_vm.$i18n.locale]))]),_c('a-button',{attrs:{"disabled":_vm.cardsIsEmpty,"type":"danger"},on:{"click":function($event){return _vm.removeItem(_vm.selectedPosts)}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.categoryProps.results || [],"loading":_vm.loading,"pagination":false,"scroll":{ x: 3500, y: 600 },"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('a-checkbox',{attrs:{"checked":_vm.selectedPosts.includes(+item.id),"value":+item.id},on:{"change":function($event){return _vm.toggleItemId(item.id)}}}),_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: 'headquarters-update',
            params: { id: item.id }
          })}}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"activity_title",fn:function(item){return [_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: 'headquarters-update',
            params: { id: item.id }
          })}}},[_c('b',[_vm._v(_vm._s(item.title || "-"))])])]}},{key:"photo",fn:function(item){return [(!item.photo)?_c('div',[_vm._v("no photo")]):_c('div',[_c('img',{staticStyle:{"width":"80px","height":"80px","object-fit":"cover"},attrs:{"alt":"profile_img","src":item.photo}})])]}},{key:"link",fn:function(item){return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"level",fn:function(item){return [_c('a-input-number',{staticStyle:{"width":"70px"},attrs:{"min":1},on:{"pressEnter":function($event){return _vm.updateLevel(item.id, $event)}},model:{value:(item.level),callback:function ($$v) {_vm.$set(item, "level", $$v)},expression:"item.level"}})]}},{key:"author",fn:function(item){return [_vm._v(" "+_vm._s(item.author || "-")+" ")]}},{key:"flayers",fn:function(item){return [_c('a-button',{staticStyle:{"width":"90%","background-color":"#26a6de"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'flayers',
            params: { act_id: item.id, type: _vm.$route.params.type }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])]}},{key:"departments",fn:function(item){return [_c('a-button',{staticStyle:{"width":"90%","background-color":"#26a6de"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'departments',
            params: { act_id: item.id, type: _vm.$route.params.type }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])]}},{key:"about",fn:function(item){return [_c('a-button',{staticStyle:{"width":"90%","background-color":"#26a6de"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'aboutAct',
            params: { act_id: item.id, type: _vm.$route.params.type }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])]}},{key:"doc",fn:function(item){return [_c('a-button',{staticStyle:{"width":"90%","background-color":"#26a6de"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'doc',
            params: { act_id: item.id, type: _vm.$route.params.type }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"to_slider",fn:function(item){return [_c('a-badge',{attrs:{"status":item.on_slider ? 'success' : 'error',"text":item.on_slider ? 'Да' : 'Нет'}})]}},{key:"is_blog",fn:function(item){return [_c('a-badge',{attrs:{"status":item.is_blog ? 'success' : 'error',"text":item.is_blog ? 'Да' : 'Нет'}})]}},{key:"sub_content",fn:function(item){return [_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'faq-ques',
            params: {
              theme_id: item.id
            }
          })}}},[_vm._v(" Добавить ")])]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'headquarters-update',
            params: { id: item.id }
          })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"cancel-text":"Нет","ok-text":"Да","title":"Вы действительно хотите удалить?"},on:{"confirm":function($event){return _vm.removeItem(item.id)}}},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"danger"}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{key:_vm.perPage,attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.categoryProps.count || 0},on:{"change":_vm.toPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }